import React from "react"

import Layout from "../layouts"
import { themeGet } from 'styled-system'
import { Container } from '../components/Panel'

import styled, { keyframes } from 'styled-components'

const fadeIn = keyframes`
	0% {
		transform: translate(0, 100px);
		opacity:0;
	}
	100% {
		transform: translate(0, 0);
		opacity:1;
	}
`	

const Contents = Container.extend`
	min-height: calc(100vh - 158px);
	padding: 7.5em 0;
	@media (max-width: ${themeGet('breakpoints.6')}) {
    	padding: 0;
	}
	@media (max-width: ${themeGet('breakpoints.1')}) {
    	min-height: 100vh;
	}
`

const Text = styled.div`
	color: ${themeGet('colors.contrast.4')};
	position: absolute;
	width: 50%;
	opacity: 0;
	animation: ${fadeIn} ease 1s;
	animation-fill-mode: forwards; 
    & h1 {
    	font-size: ${themeGet('headings.9')};
    	margin-bottom: 0px;
    	margin-top: 0px;
    }

    & p {
    	font-weight: normal;
    	font-size: 26px;
    	display: inline-block;

    	& span {
    		font-weight: bold;
    		display: inline-block;
    		border-bottom: 2px solid #aaa;
    	}
    }

    @media (max-width: ${themeGet('breakpoints.5')}) {
    	& h1 {
    		font-size: ${themeGet('headings.8')};
	    }

	    & p {
	    	font-weight: normal;
	    	font-size: ${themeGet('fontSizes.5')};
	    	display: inline-block;

	    	& span {
	    		font-weight: bold;
	    		display: inline-block;
	    		border-bottom: 2px solid #998872;
	    	}
	    }
    }

    @media (max-width: ${themeGet('breakpoints.1')}) {
    	width: inherit;
	}

	@media (max-width: ${themeGet('breakpoints.0')}) {
    	& h1 {
    		font-size: ${themeGet('headings.6')};
	    }
	    & p {
	    	& span {
	    		font-weight: bold;
	    		display: block;
	    		width: fit-content;
	    	}
	    }

	}
`
const CircleContainer = styled.div`
	width: 50vw;
	margin-left: 49%;
	opacity: 0;
	animation: ${fadeIn} ease 1s;
	animation-delay: 1s;
	animation-fill-mode: forwards; 
	@media (max-width: ${themeGet('breakpoints.3')}) {
    	width: 50%;
    	margin-top: 300px;
	}
	@media (max-width: ${themeGet('breakpoints.2')}) {
    	width: 100%;
    	margin-top: 400px;
    	margin-left: 0;	
	}
	@media (max-width: ${themeGet('breakpoints.1')}) {
    	margin-top: 300px;
	}
`
const Circle1 = styled.div`
	width: 70%;
	position: relative;
	padding-top: 70%;
	background: linear-gradient(133deg, #9C9C9C 1%,#ffffff 56%);
	border-radius: 50%;
	margin-left: 10%;
	@media (max-width: ${themeGet('breakpoints.3')}) {
		background: #9C9C9C;
		opacity: 0.11;
	}
`

const Circle2 = styled.div`
	width: 40%;
	position: relative;
	padding-top: 40%;
	background-image: linear-gradient(133deg,#998872 1%,#ffffff 56%);
	border-radius: 50%;
	margin-top: -35%;
	margin-left: 50%;
	@media (max-width: ${themeGet('breakpoints.3')}) {
		width: 25%;
		padding-top: 25%;
		background-image: linear-gradient(137deg, #666666 3%, rgba(175,156,130,0.00) 98%);
		margin-top: -100%;
		margin-left: 80%;
	}
	@media (max-width: ${themeGet('breakpoints.2')}) {
		margin-top: -80%;
		width: 20%;
		padding-top: 20%;
	}
`

const Circle3 = styled.div`
	width: 20%;
	position: relative;
	padding-top: 20%;
	background: linear-gradient(133deg, #9C9C9C 1%,#ffffff 56%);
	border-radius: 50%;
	margin-top: -10%;
	margin-left: 25%;
	opacity: 0;
	animation: ${fadeIn} ease 1s;
	animation-delay: 1s;
	animation-fill-mode: forwards; 
	@media (max-width: ${themeGet('breakpoints.3')}) {
		width: 5%;
		padding-top: 5%;
		background: #AF9C82;
    	margin-top: 30%;
    	margin-left: 95%;
    }
    @media (max-width: ${themeGet('breakpoints.2')}) {
		width: 10%;
		padding-top: 10%;
    	margin-top: 40%;
    	margin-left: 88%;
    }
`


const ContactPage = () => (
  <Layout>
	<Contents>
		<Text>
			<h1>Contact</h1>
			<p>I live in Haarlem, the Netherlands.<br/>
				For inquiries e-mail <span>hello@dogan.co</span></p>
		</Text>
		<CircleContainer>
			<Circle1 />
			<Circle2 />	
		</CircleContainer>
		<Circle3 />	
	</Contents>
  </Layout>
)

export default ContactPage
